import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import RichContentDescription from "./richContentDescription.component"
import { colors, fontSizes, percent } from "../structure/theme"
import breakpoint from "styled-components-breakpoint"
import ProductDocumentLinks from "./productDocumentLinks.component"
import PaymentButton from "../payment/paymentButton.component"

const Description = styled.div`
  margin-bottom: 15px;
  text-align: center;
  padding: 0 ${100 / 12}%;
  max-width: 100%;
  font-size: ${fontSizes(`large`)};
  p {
    margin: 0;
  }
`

const Wrapper = styled.div`
  padding: 25px;
  background-color: ${colors(`grey.g300`)};

  ${breakpoint(`medium`)`
    padding: 25px ${percent(1 / 12)};
  `}
`

const CallToActionWrapper = styled.div`
  display: none;

  ${breakpoint(`medium`)`
    margin-top: 24px;
    text-align: center;
    display: block;
    padding: 0 40%;
  `};
`

export default function ProductDescription ({ product }) {
  const { shortDescription, longDescription, uncoveredGuarantee } = product

  return (
    <>
      <Description dangerouslySetInnerHTML={{ __html: shortDescription }} />
      <Wrapper data-testid="product_description">
        <RichContentDescription content={longDescription} uncovered={uncoveredGuarantee} />
        <ProductDocumentLinks product={product} />
      </Wrapper>
      <CallToActionWrapper>
        <PaymentButton product={product} />
      </CallToActionWrapper>
    </>
  )
}

ProductDescription.propTypes = {
  product: PropTypes.shape({
    shortDescription: PropTypes.string,
    longDescription: PropTypes.string,
    uncoveredGuarantee: PropTypes.string,
  }).isRequired,
}
