import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { Button } from "../structure/theme/button.component"
import MousePicto from "../../components/pictos/mouse.component"
import PhonePicto from "../../components/pictos/phone2.component"

const buttonStyled = css`
  text-transform: none;
  min-width: 254px;
  width: 100%;
`

const ButtonWebStyled = styled(Button)`
  ${buttonStyled};
`

export const ButtonWeb = ({ onClick, buttonsColor, ...props }) => {
  return (
    <ButtonWebStyled {...props} textColor={buttonsColor} onClick={onClick} data-testid="product_mouse_button">
      <MousePicto size={24} color="#fff" />
      Internet
    </ButtonWebStyled>
  )
}

ButtonWeb.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonsColor: PropTypes.string.isRequired,
}

const SmallText = styled.span`
  margin-right: 6px;
  font-weight: 500;
`

const ButtonPhoneStyled = styled(Button)`
  ${buttonStyled};
`

export const ButtonPhone = ({ buttonsColor, href, phoneNumber, ...props }) => {
  return (
    <ButtonPhoneStyled href={href} backgroundColor={buttonsColor} {...props} data-testid="product_phone_button">
      <SmallText>Au</SmallText>
      <PhonePicto size={27} color="#fff" />
      {phoneNumber}
    </ButtonPhoneStyled>
  )
}

ButtonPhone.propTypes = {
  buttonsColor: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
}
