import { useState, useEffect, useRef, useCallback, useMemo, memo } from "react"
import PropTypes from "prop-types"
import { useWindowResize } from "@homeserve/react-hooks"

const ENTERING = 1
const ENTERED = 2
const BEFORE_EXISTING = 3
const EXITING = 4
const EXITED = 5

function onTransitionEnd (node, done) {
  if (process.env.NODE_ENV === `production` && process.env.ENVIRONMENT === `mock`) {
    return window.requestAnimationFrame(done)
  }

  const transitionend = () => {
    node.removeEventListener(`transitionend`, transitionend)
    window.requestAnimationFrame(done)
  }

  node.addEventListener(`transitionend`, transitionend, false)
}

function Collapse ({ children, isInitiallyOpen = false, transitionTime = 0.2, open = isInitiallyOpen }) {
  const ref = useRef()
  const [state, setState] = useState(open ? ENTERED : EXITED)
  const [openRequest, setOpenRequest] = useState(open)
  const windowSize = useWindowResize()

  const toggle = useCallback(() => {
    setOpenRequest(o => !o)
  }, [])

  useEffect(() => {
    setOpenRequest(open)
  }, [open])

  useEffect(() => {
    switch (state) {
      case ENTERED: {
        if (!openRequest) {
          onTransitionEnd(ref.current, () => setState(EXITED))
          setState(BEFORE_EXISTING)
        }
        break
      }
      case BEFORE_EXISTING: {
        setState(EXITING)
        break
      }
      case EXITED: {
        if (openRequest) {
          onTransitionEnd(ref.current, () => setState(ENTERED))
          setState(ENTERING)
        }
        break
      }
    }
  }, [state, openRequest])

  const transitionStyles = useMemo(
    () => ({
      [ENTERING]: { opacity: 1, height: ref.current?.scrollHeight },
      [ENTERED]: { opacity: 1, height: `auto` },
      [BEFORE_EXISTING]: { opacity: 1, height: ref.current?.scrollHeight },
      [EXITING]: { opacity: 0, height: 0 },
      [EXITED]: { opacity: 0, height: 0 },
    }),
    [ref.current?.scrollHeight, windowSize],
  )

  return children({
    ref,
    style: {
      ...transitionStyles[state],
      transition: `height ${transitionTime}s, opacity ${transitionTime}s`,
      overflow: `hidden`,
    },
    toggle,
    isOpen: state === ENTERING || state === ENTERED,
  })
}

Collapse.propTypes = {
  children: PropTypes.func.isRequired,
  isInitiallyOpen: PropTypes.bool,
  transitionTime: PropTypes.number,
  open: PropTypes.any,
}

export default memo(Collapse)
