import React from "react"
import PropTypes from "prop-types"
import Picto from "../picto.component"

export default function Phone2 ({ color = `#fff`, ...props }) {
  return (
    <Picto viewBox="0 0 21.49 29.68" {...props}>
      <g>
        <path
          d="M2.06,2.74,12.64.1a2.79,2.79,0,0,1,3.39,2l5.35,21.44a2.8,2.8,0,0,1-2,3.4h0L8.76,29.61a2.85,2.85,0,0,1-3.39-2L0,6.13A2.86,2.86,0,0,1,2.06,2.74Zm3.86,1.4a.33.33,0,0,1-.37-.21.34.34,0,0,1,.22-.37l4-1a.26.26,0,0,1,.36.22.26.26,0,0,1-.21.36h0Zm7.5,21.43a.68.68,0,0,1,.85.51h0a.67.67,0,0,1-.51.84.68.68,0,0,1-.85-.51.84.84,0,0,1,.51-.84ZM1.67,7l13.2-3.29,4.76,19.11L6.44,26.07Z"
          transform="translate(0.03 -0.02)"
          style={{ fill: color, fillRule: `evenodd` }}
        />
      </g>
    </Picto>
  )
}

Phone2.propTypes = {
  color: PropTypes.string,
}
