import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import loadable from "@loadable/component"
import { modules } from "../structure/theme"
import { Button } from "../structure/theme/button.component"
import { usePaymentContext } from "./payment.provider"
import { addToCartTracking } from "../thirdParties/gtm/tagManager"
import { useCampaignProduct } from "../campaign/campaign.utils"
import { mapUniverseToPaymentUniverse } from "./payment.utils"
import { ButtonWeb } from "../campaign/bannerProductButton.component"
import { ModalButton } from "../../components/modal/modal.component"
import { useTranslation } from "../translation/translate.component"
import useNavigation from "../navigation/useNavigation"

const ProductUpsell = loadable(() => import(`../product/upsell/productUpsell.component`), { ssr: false })

const ButtonFullWidth = styled(Button).attrs({
  textColor: modules(`product.paymentButton.color`),
  backgroundColor: modules(`product.paymentButton.backgroundColor`),
  major: true,
})`
  width: 100%;
`

const OrderButton = forwardRef(({ phoneVariant, buttonsColor, ...props }, ref) => {
  const { t } = useTranslation()
  if (phoneVariant) {
    return <ButtonWeb ref={ref} {...props} backgroundColor={buttonsColor} />
  }

  return (
    <ButtonFullWidth {...props} backgroundColor={buttonsColor} ref={ref} data-testid="payment_order_btn">
      {t(`common:payment.order_button`)}
    </ButtonFullWidth>
  )
})

OrderButton.propTypes = {
  phoneVariant: PropTypes.bool.isRequired,
  buttonsColor: PropTypes.string,
}

function extractRedirectProductFields (redirectProduct) {
  return (
    redirectProduct && {
      name: redirectProduct.name,
      slug: redirectProduct.slug,
    }
  )
}

function getMinimalProduct (product) {
  const {
    originalId: id,
    name,
    slug,
    impactProduct,
    housingType,
    sprf,
    universes,
    tunnelSpecificity,
    ownerRedirectProduct,
    occupantRedirectProduct,
    renterRedirectProduct,
    otherRedirectProduct,
    blacklistedDepartments,
    universDescription,
  } = product

  return {
    id,
    name,
    slug,
    housingType,
    sprf,
    tunnelSpecificity,
    impactProduct,
    universDescription,
    universes: universes.map(mapUniverseToPaymentUniverse),
    ownerRedirectProduct: extractRedirectProductFields(ownerRedirectProduct),
    occupantRedirectProduct: extractRedirectProductFields(occupantRedirectProduct),
    renterRedirectProduct: extractRedirectProductFields(renterRedirectProduct),
    otherRedirectProduct: extractRedirectProductFields(otherRedirectProduct),
    blacklistedDepartments,
  }
}

const PaymentButton = forwardRef(({ product, upsellProduct, upsellHook, buttonsColor, phoneVariant = false }, ref) => {
  const { setProduct } = usePaymentContext()
  const [campaignProduct] = useCampaignProduct(product)
  const [campaignUpsellProduct, campaignUpsellProductReady] = useCampaignProduct(upsellProduct)
  const minimalProduct = getMinimalProduct(campaignProduct)
  const addToCartTrackingAction = addToCartTracking(campaignProduct)
  const { navigate } = useNavigation()

  function navigateToTunnel (minProduct) {
    addToCartTrackingAction()
    setProduct(minProduct)
    navigate(`paymentStep1`)
  }

  if (campaignUpsellProduct && upsellHook) {
    const minimalUpsellProduct = getMinimalProduct(campaignProduct)

    return (
      <ModalButton
        modalComponent={ProductUpsell}
        modalProps={{
          product: campaignProduct,
          productUpsell: campaignUpsellProduct,
          hook: upsellHook,
          onClickUpsell: () => navigateToTunnel(minimalUpsellProduct),
          onClickProduct: () => navigateToTunnel(minimalProduct),
        }}
      >
        {openModal => (
          <OrderButton
            onClick={() => openModal()}
            ref={ref}
            buttonsColor={buttonsColor}
            disabled={!campaignUpsellProductReady}
            phoneVariant={phoneVariant}
          />
        )}
      </ModalButton>
    )
  }

  return (
    <OrderButton
      onClick={() => navigateToTunnel(minimalProduct)}
      buttonsColor={buttonsColor}
      ref={ref}
      phoneVariant={phoneVariant}
    />
  )
})

PaymentButton.propTypes = {
  phoneVariant: PropTypes.bool,
  buttonsColor: PropTypes.string,
  product: PropTypes.shape({
    universDescription: PropTypes.string.isRequired,
    originalId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    impactProduct: PropTypes.shape({
      variants: PropTypes.arrayOf(
        PropTypes.shape({
          standardPrice: PropTypes.number.isRequired,
          offerPrice: PropTypes.number,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    housingType: PropTypes.string.isRequired,
    sprf: PropTypes.bool.isRequired,
    universes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }),
    ).isRequired,
    tunnelSpecificity: PropTypes.string.isRequired,
    ownerRedirectProduct: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
    occupantRedirectProduct: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
    renterRedirectProduct: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
    otherRedirectProduct: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
    blacklistedDepartments: PropTypes.array.isRequired,
  }).isRequired,
  upsellProduct: PropTypes.object,
  upsellHook: PropTypes.any,
}

export default PaymentButton
