import React from "react"
import styled from "styled-components"
import { colors, fontSizes } from "../structure/theme"
import PropTypes from "prop-types"
import PdfIcon from "../../components/pictos/pdfIcon.component"
import { useTranslation } from "../translation/translate.component"
import { useProductDocumentUrl } from "./product.utils"
import { DownloadLink } from "../download/downloadLink.component"

const Link = styled(DownloadLink)`
  display: block;
  color: ${colors(`grey.g800`)};
  margin: 10px 0;

  &:hover {
    font-weight: 600;
  }
`

const LinkText = styled.span`
  margin-left: 10px;
`

const Title = styled.div`
  margin-top: 20px;
  font-size: ${fontSizes(`large`)};
  font-weight: 900;
`

function DocumentLink ({ iconLink, href, children }) {
  return (
    <Link href={href} data-testid={arguments[0][`data-testid`]}>
      <PdfIcon size={20} link={iconLink} />
      <LinkText>{children}</LinkText>
    </Link>
  )
}

DocumentLink.propTypes = {
  iconLink: PropTypes.bool,
  href: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

export default function ProductDocumentLinks ({ product }) {
  const { t } = useTranslation()
  const ipidSheetUrl = useProductDocumentUrl(product.originalId, `ipidSheet`)
  const cguSheetUrl = useProductDocumentUrl(product.originalId, `cguSheet`)
  const commercialSheetUrl = useProductDocumentUrl(product.originalId, `commercialSheet`) || ``

  return (
    <>
      <Title>{t(`common:product.product_documents_title`)}</Title>
      <p>{t(`common:product.product_documents_description`)}</p>
      {commercialSheetUrl && (
        <DocumentLink href={commercialSheetUrl} data-testid="download_commercial">
          {t(`common:product.product_documents_commercial_link`)}
        </DocumentLink>
      )}
      <DocumentLink iconLink={!!commercialSheetUrl} href={cguSheetUrl} data-testid="download_cgu">
        {t(`common:product.product_documents_cgu_link`)}
      </DocumentLink>
      <DocumentLink iconLink href={ipidSheetUrl} data-testid="download_ipid">
        {t(`common:product.product_documents_ipid_link`)}
      </DocumentLink>
    </>
  )
}

ProductDocumentLinks.propTypes = {
  product: PropTypes.shape({
    originalId: PropTypes.number.isRequired,
  }).isRequired,
}
