import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import Proptypes from "prop-types"
import Collapse from "../../components/collapse/collapse.component"
import { fontSizes, useThemeContext } from "../structure/theme"
import breakpoint from "styled-components-breakpoint"
import cssChecked from "../../components/pictos/cssChecked"
import cssArrow from "../../components/pictos/cssArrow"
import cssCross from "../../components/pictos/cssCross"

const questionCss = css`
  position: relative;
  background-color: white;
  margin-top: 25px;
  padding: 25px 25px 25px 10px;
  transition: padding 0.5s;
  
  &:first-child {
    margin-top: 0;
  }

  &:after {
    position: absolute;
    right: 10px;
    top: 30px;
    color: ${props => props.theme.colors.primary};
    content: " ";
    width: 20px;
    height: 20px;
    background-image: url("${cssArrow()}");
    background-repeat: no-repeat;
    background-position: center center;
    transform-origin: center center;
    transform: ${props => props.isOpen && `rotate(90deg)`};
    transition: transform 0.5s, top 0.5s;
    font-weight: 900;
    cursor: pointer;
  }
  
  ${breakpoint(`medium`)`
    padding: ${props => (props.isOpen ? 30 : 10)}px 100px;
    
    &:after {
      top: ${props => (props.isOpen ? 35 : 15)}px;
      right: 80px;
    }
  `}

  p {
    text-transform: uppercase;
    font-size: ${props => props.theme.fonts.sizes.large};
    margin: 0;
    font-weight: 900;
    cursor: pointer;
  }
`

const wysiwygCss = css`
  b,
  strong {
    font-weight: 900;
  }
  > ul {
    list-style: none;
    padding: 15px 0;

    > li {
      padding-left: 35px;
      margin-bottom: 10px;
      position: relative;
      font-size: ${fontSizes(`regular`)};

      &::before {
        content: "";
        background-color: white;
        background-image: url("${props => props.icon}");
        background-repeat: no-repeat;
        background-position: center center;
        color: ${props => props.theme.colors.accent};
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
        width: 25px;
        height: 25px;
      }

      ul {
        padding: 0;
        font-size: ${fontSizes(`small`)};
        li {
          list-style-type: none;
          &:before {
            content: "-";
            display: inline-block;
            padding-right: 5px;
          }
        }
      }
    }
  }
`

const Question = styled.div`
  ${questionCss};
`

const ResponseContent = styled.div`
  ${wysiwygCss};
  padding: 0 10px ${props => (props.isOpen ? 25 : 0)}px 10px;
  background-color: white;

  ${breakpoint(`medium`)`
    padding: 0px 100px ${props => (props.isOpen ? 25 : 0)}px 100px;
  `}
`

function DescriptionSection ({ toggle, isOpen, item, reference, style, icon }) {
  return (
    <>
      <Question
        isOpen={isOpen}
        onClick={toggle}
        dangerouslySetInnerHTML={{ __html: item.title }}
        data-testid={`description_collapse_title_${item.id}`}
      />
      <ResponseContent
        ref={reference}
        style={style}
        data-testid={`description_collapse_content_${item.id}`}
        isOpen={isOpen}
        icon={icon}
        dangerouslySetInnerHTML={{ __html: item.content }}
      />
    </>
  )
}

DescriptionSection.propTypes = {
  toggle: Proptypes.func.isRequired,
  isOpen: Proptypes.bool.isRequired,
  reference: Proptypes.any.isRequired,
  style: Proptypes.object.isRequired,
  icon: Proptypes.string.isRequired,
  item: Proptypes.shape({
    title: Proptypes.string.isRequired,
    id: Proptypes.number.isRequired,
    content: Proptypes.string.isRequired,
  }).isRequired,
}

export default function RichContentDescription ({ content, uncovered }) {
  const checkIcon = cssChecked()
  const crossIcon = cssCross()
  const getTitle = item => item.substring(item.indexOf(`<p>`), item.indexOf(`</p>`) + 4)
  const getContent = item => item.substring(item.indexOf(`</p>`) + 4)

  const items = content
    ? content.split(`<hr>`).map((item, key) => ({
        id: key + 1,
        title: getTitle(item),
        content: getContent(item),
      }))
    : []
  const uncoveredItems = uncovered
    ? uncovered
        .split(`<hr>`)
        .map((item, key) => {
          return {
            id: key + 1 + items.length,
            title: getTitle(item),
            content: getContent(item),
          }
        })
        .filter(item => !!item.content)
    : []
  const {
    breakpoints: { medium },
  } = useThemeContext()

  const [isInitiallyOpen, setIsInitiallyOpen] = useState(null)
  useEffect(() => {
    setIsInitiallyOpen(window.innerWidth >= medium)
  }, [])

  if (!content && !uncovered) {
    return null
  }

  return (
    <>
      {items.map(item => (
        <Collapse key={`${item.id}-ssr`} isInitiallyOpen={isInitiallyOpen === null || isInitiallyOpen}>
          {({ isOpen, toggle, ref, style }) => (
            <DescriptionSection
              isOpen={isOpen}
              toggle={toggle}
              reference={ref}
              style={style}
              item={item}
              icon={checkIcon}
            />
          )}
        </Collapse>
      ))}
      {uncoveredItems.map(item => (
        <Collapse key={`${item.id}-ssr`} isInitiallyOpen={isInitiallyOpen === null || isInitiallyOpen}>
          {({ isOpen, toggle, ref, style }) => (
            <DescriptionSection
              isOpen={isOpen}
              toggle={toggle}
              reference={ref}
              style={style}
              item={item}
              icon={crossIcon}
            />
          )}
        </Collapse>
      ))}
    </>
  )
}

RichContentDescription.propTypes = {
  content: Proptypes.string,
  uncovered: Proptypes.string,
}
