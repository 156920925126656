import React from "react"
import PropTypes from "prop-types"
import Picto from "../picto.component"

export default function Mouse ({ color = `#fff`, ...props }) {
  return (
    <Picto viewBox="0 0 15.6 25.5" {...props}>
      <g>
        <path
          fill={color}
          d="M7.8,25.5C3.5,25.5,0,22,0,17.7V7.8C0,3.5,3.6,0,7.9,0.1c4.2,0,7.6,3.5,7.7,7.7v9.9   C15.6,22,12.1,25.5,7.8,25.5z M7.8,1.4c-3.5,0-6.4,2.9-6.4,6.4v9.9c0,3.5,2.9,6.4,6.4,6.4s6.4-2.9,6.4-6.4V7.8   C14.1,4.3,11.3,1.4,7.8,1.4L7.8,1.4z"
        />
      </g>
      <g transform="translate(7.077 4.6)">
        <path
          fill={color}
          d="M0.7,4.2C0.3,4.2,0,3.9,0,3.5V0.7C0,0.3,0.3,0,0.7,0s0.7,0.3,0.7,0.7c0,0,0,0,0,0.1v2.8   C1.4,3.9,1.1,4.2,0.7,4.2z"
        />
      </g>
    </Picto>
  )
}

Mouse.propTypes = {
  color: PropTypes.string,
}
